<template>
    <!-- 支付成功组件 -->
    <div>
        <!-- 付款成功部分 -->
        <div class="photograph_pay_success_box">
            <img :src="require('@/assets/images/photograph/@1x/icon_success.png')" alt="">
            <div class="photograph_pay_success_text1">付款成功</div>
            <div class="photograph_pay_success_text1">系统正在为您派单，请耐心等候</div>
            <!-- <div class="photograph_pay_success_text2">可在订单详情中查看接单情况</div> -->
            <el-button
                type="text"
                @click="$router.push('/main')">返回首页</el-button>
        </div>
        <hr>
        <!-- 猜你喜欢部分 -->
        <!-- <div class="photograph_pay_guess_box">
            标题
            <div class="photograph_pay_guess_title">猜你喜欢</div>
            轮播图区域
            <div></div>
        </div> -->
        <div class="photograph_pay_success_detail_title">订单详情</div>
        <order-serve></order-serve>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    components: {
        orderServe: () => import('@/components/orderServe')
    }
}
</script>

<style scoped>
.photograph_pay_success_box{
    text-align: center;
}
/* 文字 */
.photograph_pay_success_text1{
    font-size: 18px;
    margin-bottom: 10px;
}
.photograph_pay_success_text2{
    font-size: 13px;
    color: #909399;
}
/* 猜你喜欢部分 */
/* 猜你喜欢标题 */
.photograph_pay_guess_title{
    margin-top: 50px;
    font-size: 26px;
    font-weight: bold;
}
/* 订单详情标题 */
.photograph_pay_success_detail_title{
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: bold;
}
</style>
